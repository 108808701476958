import React, { FC } from 'react';

import styled from 'styled-components';

type LabelProps = {
  displayName: string;
  optional: boolean;
  htmlFor: string;
  subText?: string;
};

const StyledLabel = styled.label`
  ${(props: { optional?: boolean }) =>
    props.optional &&
    `
  :after {
    content: "  (Optional)";
    white-space:pre-wrap;
    color: #999999;
    font-weight: 400;
  }
`}
  display: block;
  color: #333333;
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
`;

export const StyledCheckboxLabel = styled(StyledLabel)`
  touch-action: manipulation;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 0px;
`;

const InfoSubText = styled.div`
  color: #717171;
  font-size: 12px;
  margin: -4px 0 7px;
  line-height: 18px;
`;

export const StyledCheckboxWrapper = styled.div`
  display: inline;
`;

export const Label: FC<LabelProps> = ({ displayName, subText, ...props }) => (
  <>
    <StyledLabel {...props}>{displayName}</StyledLabel>
    {subText && <InfoSubText>{subText}</InfoSubText>}
  </>
);
