import React from 'react';

import { Checkbox, CheckboxProps } from '@dsch/form-kit';
import styled from 'styled-components';

export type AdditionalProps = CheckboxProps & {
  hasError?: boolean;
  checkboxText?: any;
  isOptional?: boolean;
  htmlFor?: string;
};

type StyledProps = {
  hasError?: boolean;
  disabled?: boolean;
  checked?: boolean;
  isOptional?: boolean;
};

const StyledCheckboxLabel = styled.label`
  touch-action: manipulation;
  vertical-align: middle;
  cursor: pointer;
  padding-right: ${({ theme }) => theme.spacing.S12};
  /* text-indent: -${({ theme }) => theme.spacing.M32}; */
  padding-left: ${({ theme }) => theme.spacing.M32};

  &:after {
    content: ${(props: StyledProps) =>
      props.isOptional &&
      `'\\0020(Optional)'
      `};
    color: ${({ theme }) => theme.color.GREY};
  }
`;

const StyledCheckboxContainer = styled.div<StyledProps>`
  position: relative;
  cursor: pointer;
  ${({ theme }) => theme.fontSize.P14};
  display: flex;
  color: ${({ theme }) => theme.color.GREY_DARKER};
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};

  input {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid transparent;
  }

  ${StyledCheckboxLabel} {
    &:before {
      ${(props) =>
        props.hasError &&
        `
        border: 2px solid #d50000;
        border-radius: 2px;
        background-image: none;
        background-color: #ffffff;
      `};
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  width: ${({ theme }) => theme.spacing.M20};
  height: ${({ theme }) => theme.spacing.M20};
  margin-top: 3px;

  :before {
    content: '';
    display: inline-block;
    background-size: contain;
    vertical-align: middle;
    margin: 0 10px 0 0;
    width: 20px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 52.2 (67145) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3EIcon/Form/Checkbox%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Icon/Form/Checkbox' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg%3E%3Crect id='Rectangle-10' fill='%23EE16B7' opacity='0' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3Cpath d='M2,1 C1.44771525,1 1,1.44771525 1,2 L1,22 C1,22.5522847 1.44771525,23 2,23 L22,23 C22.5522847,23 23,22.5522847 23,22 L23,2 C23,1.44771525 22.5522847,1 22,1 L2,1 Z' id='Field_3-Copy-3' stroke='%23DDDDDD' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3Crect id='Rectangle' fill='%23FFFFFF' x='2' y='2' width='20' height='20'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
  }

  :checked {
    :before {
      content: '';
      background-color: #cccccc;
      /* TODO: Investigate why this looks different when moved from Toolkit repo - colour is lighter and won't display without line 68 (height) is removed */
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 52.2 (67145) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3EIcon/Form/CheckboxSelected%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Icon/Form/CheckboxSelected' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg%3E%3Crect id='Rectangle-3' fill='%23EE16B7' opacity='0' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3Cpath d='M21.3333333,0 L2.66666667,0 C1.2,0 0,1.2 0,2.66666667 L0,21.3333333 C0,22.8 1.2,24 2.66666667,24 L21.3333333,24 C22.8,24 24,22.8 24,21.3333333 L24,2.66666667 C24,1.2 22.8,0 21.3333333,0 Z' id='Shape' fill='%233363BA'%3E%3C/path%3E%3Cpolygon id='Path' fill='%23FFFFFF' points='9.33333333 18.6666667 2.66666667 12.2564103 4.53333333 10.4615385 9.33333333 15.0769231 19.4666667 5.33333333 21.3333333 7.12820513'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-size: cover;
    }
  }
  :disabled {
    :before {
      content: '';
      background-color: #dddddd;
      background-image: none;
    }
  }
  :disabled:checked {
    :before {
      content: '';
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 52.1 (67048) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3ESelected disabled%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Form-Text-Input-Copy' transform='translate(-489.000000, -2239.000000)'%3E%3Crect fill='%23FFFFFF' x='0' y='0' width='1222' height='2406'%3E%3C/rect%3E%3Cg id='Selected-disabled-checkbox' transform='translate(147.000000, 2239.000000)'%3E%3Cg id='Selected-disabled' transform='translate(342.000000, 0.000000)'%3E%3Crect id='Rectangle' fill='%234A4A4A' fill-rule='nonzero' opacity='0' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3Cpath d='M21.3333333,0 L2.66666667,0 C1.2,0 0,1.2 0,2.66666667 L0,21.3333333 C0,22.8 1.2,24 2.66666667,24 L21.3333333,24 C22.8,24 24,22.8 24,21.3333333 L24,2.66666667 C24,1.2 22.8,0 21.3333333,0 L21.3333333,0 Z M9.33333333,18.6666667 L2.66666667,12.2564103 L4.53333333,10.4615385 L9.33333333,15.0769231 L19.4666667,5.33333333 L21.3333333,7.12820513 L9.33333333,18.6666667 L9.33333333,18.6666667 Z' id='Shape' fill='%23DDDDDD'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }
`;

const DefaultCheckbox = ({
  name,
  className,
  disabled,
  checked,
  id,
  hasError,
  checkboxText,
  onChange,
  isOptional,
  htmlFor,
}: AdditionalProps) => {
  return (
    <StyledCheckboxContainer
      disabled={disabled}
      hasError={hasError}
      data-testid={`${name}-label`}
    >
      <StyledCheckbox
        name={name}
        id={id ? id : name}
        className={className}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <StyledCheckboxLabel
        htmlFor={id ?? (name || htmlFor)}
        isOptional={isOptional}
        disabled={disabled}
        checked={checked}
      >
        {checkboxText}
      </StyledCheckboxLabel>
    </StyledCheckboxContainer>
  );
};

export { DefaultCheckbox as Checkbox };
